<template>
  <div class="panel">
    <div class="menu">
      <div class="search">
        <img src="@/assets/icons/search.png" alt />
        <input
          type="text"
          placeholder="请输入关键字搜索"
          v-model="searchValue"
          @change="onChange"
        />
      </div>
      <a-tree
        style="max-height: 76vh;overflow-x: hidden;overflow-y: auto;"
        v-model="selected"
        checkable
        :auto-expand-parent="true"
        :tree-data="menuList"
      />
    </div>

    <a-checkbox-group style="width: 100%" v-model="list">
      <div class="cards" v-if="selectedMenu.length > 0" style="max-height: 85vh;overflow-x: hidden;overflow-y: auto;">
        <div class="card" v-for="item in selectedMenu" :key="item.id">
          <img :src="item.modelImg" alt />
          <div class="footer">
            <div class="title">{{ item.name }}</div>
            <a-checkbox :value="item.id"></a-checkbox>
          </div>
        </div>
      </div>
      <div class="empty" v-else>
        <a-empty />
      </div>
    </a-checkbox-group>

    <div class="list" style="max-height: 85vh;overflow-x: hidden;overflow-y: auto;">
      <div class="title">
        <span class="icon"></span>
        <span>构建清单</span>
      </div>

      <div v-if="selectedList.length > 0">
        <div class="item" v-for="item in selectedList" :key="item.id">
          <div>{{ item.name }}</div>
          <img src="@/assets/icons/delete.png" alt @click="remove(item)" />
        </div>
        <button class="primary-button" @click="save">开始设计</button>
      </div>
      <div v-else style="padding-top: 20px">
        <a-empty />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { fetchList, fetchListNum } from "@/api/book";
export default {
  data() {
    return {
      searchValue: "",
      menu: [],
      selected: [],

      list: [],
      filteredTypeList: [],
      menuList: []
    };
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    searchMenu() {
      return this.searchValue
        ? this.menu.filter((item) => item.name.indexOf(this.searchValue) > -1)
        : this.menu;
    },
    selectedMenu() {
      if (this.selected.length > 0) {
        const result = [];
        this.selected.forEach((id) => {
          const obj = this.menu.find((item) => item.id === id);
          if(obj){
            result.push(obj);
          }
        });
        result.sort((a, b) => a.sort - b.sort)
        return result;
      } else {
        return [];
      }
    },
    selectedList() {
      if (this.list.length > 0) {
        const result = [];
        this.list.forEach((id) => {
          const obj = this.menu.find((item) => item.id === id);
          result.push(obj);
        });
        return result;
      } else {
        return [];
      }
    },
    typeList() {
      return this.findDataDict("bim_calc_book_type");
    },
  },
  mounted() {
    // this.menu = [

    // ];
    
    fetchList({
      pageNum: 1,
      pageSize: 99,
    }).then((res) => {
      console.log("book res", res);

      if (Array.isArray(res.list)) {
        this.menu = res.list;
        // this.total = res.totalSize;
        fetchListNum().then((res) => {
          if (typeof res === "object") {
            this.filteredTypeList = this.typeList.map((item) => {
              const list = Object.keys(res);
              let num = 0;
              const key = list.find((key) => key === item.value);
              if (key) {
                num = res[key];
              }
              let children = this.menu.filter((v) => v.type == item.value);
              children = children.map(v=>{
                return {
                  title: v.name,
                  key: v.id,
                };
              })
              console.log(children)
              return {
                title: children.length > 0 ? item.name + "(" + num + ")" : item.name,
                key: item.key,
                children: children
              };
            });
            this.menuList = JSON.parse(JSON.stringify(this.filteredTypeList));
          }
        });
      }
    });
  },

  methods: {
    onChange(e){
      const value = e.target.value;
      this.menuList = this.filteredTypeList.reduce((a, obj) => {
        const children = obj.children.filter(({ title }) => title.indexOf(value) > -1);
        if (children.length > 0) a.push({ ...obj, children }); 
        return a;  
      }, [])
    },
    onSelect(selectedKeys, info) {
      console.log('onSelect', info);
      this.selected = selectedKeys;
    },
    remove(item) {
      const index = this.list.indexOf(item.id);
      this.list.splice(index, 1);
    },
    save() {
      console.log(this.list);

      window.localStorage.setItem(
        "selected-books",
        JSON.stringify(this.selectedList)
      );

      this.$router.push("/task/param");
    },
  },
};
</script>

<style lang="less" scoped>
.panel {
  padding-left: 20px;
  display: flex;
  gap: 16px;
  .menu {
    width: 16vw;
    background: #fff;
    box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
    border-radius: 20px;
    padding: 20px;

    .search {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(207, 220, 238, 0.71);
      padding-bottom: 16px;
      img {
        height: 18px;
        display: block;
        margin-right: 12px;
      }
    }

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      color: #323232;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(207, 220, 238, 0.71);

      &:last-child {
        border-bottom-width: 0;
        padding-bottom: 0;
      }
    }
  }

  .empty {
    flex: 1;
    background: #fff;
    box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
    border-radius: 20px;
    padding: 60px;
  }
  .cards {
    flex: 1;
    background: #fff;
    box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
    border-radius: 20px;
    padding: 40px;

    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card {
      width: 25%;
      text-align: center;
      img {
        height: 120px;
        margin-bottom: 12px;
        padding: 10px;
      }
      .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
      }
    }
  }

  .list {
    width: 18vw;
    background: #fff;
    box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
    border-radius: 20px;
    padding: 20px;
    min-width: 240px;

    .title {
      display: flex;
      align-items: center;
      color: #0562fd;
      font-size: 18px;
      font-weight: 600;
      line-height: 1em;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(207, 220, 238, 0.71);

      .icon {
        margin-top: 1px;
        height: 0.8em;
        width: 4px;
        margin-right: 8px;
        background-color: #0562fd;
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      cursor: pointer;
      color: rgba(96, 112, 137, 0.69);
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(207, 220, 238, 0.71);

      img {
        height: 16px;
        cursor: pointer;
        display: block;
      }

      &:last-child {
        border-bottom-width: 0;
      }
    }

    .primary-button {
      margin-top: 16px;
      width: 100%;
      display: block;
      color: #fff;
      height: 38px;
      line-height: 100%;
      background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
      box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
      border-radius: 20px;
    }
  }
}
</style>