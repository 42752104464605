import { render, staticRenderFns } from "./add.vue?vue&type=template&id=58d588a7&scoped=true"
import script from "./add.vue?vue&type=script&lang=js"
export * from "./add.vue?vue&type=script&lang=js"
import style0 from "./add.vue?vue&type=style&index=0&id=58d588a7&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58d588a7",
  null
  
)

export default component.exports